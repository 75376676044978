import "./Assets/Styles/Styles.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Home, Swipe4win, Info, BuyMore, SelectPage, Results, ProtectedRoute } from "./Swip4Win";
import { Faq, HowToPlay, TermNCondition, LeaderBoard } from "./Components/SideBar";
import {ErrorPage} from "./Components";
import useOnline from "./Utilies/useOnline";
import { swipLogo } from "./Utilies/ImageExports";
import { Button } from "./Components";
import ReactGA from "react-ga4";
import Slider from "./Components/Slider";
import ResultInfo from "./Swip4Win/ResultInfo";
import Login from "./wap/component/Login"
import Otp from './wap/component/Otp'
import Subscription from "./wap/component/Subscription"
import SubStatus from "./wap/component/SubStatus"
import UpgradePlan from "./wap/component/UpgradePlan"
import Unsubscribe from "./wap/component/Unsubscribe"
import LandingPage from "./wap/component/LandingPage";
import clearCache from "./Utilies/clearCache"

function App() {
  const online = useOnline()

  useEffect(() => {
    clearCache()
    console.log("app.js called")
  },[])
  // const [promptEvent, setPromptEvent] = useState({});
  // const [openPrompt, setOpenPrompt] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("beforeinstallprompt", (e) => {
  //     console.log("beforeinstallprompt event fired : ", e);
  //     setPromptEvent(e);
  //     setOpenPrompt(true);
  //     e.preventDefault();
  //   });
  // }, []);
  // const HandleInstall = () => {
  //   console.log("clicked");
  //   promptEvent.prompt()
  //   setOpenPrompt(false);
  // };
  ReactGA.initialize([
    {
      trackingId: "G-XT5PP6P155",
    }
  ]);
  return (
    <div className="App">
      
      {/* {promptEvent !== {} && openPrompt ? (
        <div className="bg-[#f8f9fa] p-6 w-full max-w-[500px] h-20 absolute bottom-0 left-0 z-50 ">
          <div
            className=" absolute right-2 top-1 font-semibold text-2xl text-center"
            onClick={() => {
              setOpenPrompt(false);
              console.log("clicked")
            }}
          >
            x
          </div>
          <div className="flex py-1 justify-stretch " handleClick={HandleInstall}>
            <img
              src={swipLogo}
              height="50px"
              width={"50px"}
              lazy="true"
              className="align-middle"
            />
           
            <div className="flex ">
              <div className="text-center text-lg pointer justify-between pl-8 -pt-4"  >
                Add to home screen
              </div>
               <Button
                name="install"
                className={
                  "bg-gradient-to-r from-purple-600 to-pink-600 p-3 rounded-full w-36 m-auto text-white font-semibold mt-2 text-lg hover:bg-[#007bff]"
                }
                handleClick={HandleInstall}
              /> 
             
            </div>
          </div>
        </div >) : (
        ""
      )
      } */}
      {
        online ? (
          <Router>
            <Routes>
              {/* <Route path="/" exact element={<Home />} /> */}
              <Route path="/home" exact element={<Home />} />
              <Route path="/info" element={<ProtectedRoute Component={Info} />} />
              <Route path="/buy" element={<ProtectedRoute Component={BuyMore} />} />
              <Route path="/swipe-cards" element={<ProtectedRoute Component={Swipe4win} />} />
              <Route path="/results" element={<ProtectedRoute Component={Results} />} />
              <Route path="/question-info" element={<ProtectedRoute Component={Slider} />} />
              <Route path="/info-result" element={<ProtectedRoute Component={ResultInfo} />} /> 
              <Route path="/language" element={<SelectPage />} />
              <Route path="/leaderboard" element={<LeaderBoard />} />
              <Route path="/faqs" element={<Faq />} />
              <Route path="/how-to-play" element={<HowToPlay />} />
              <Route path="/errorpage" element={<ErrorPage />} />
              <Route path="/terms-conditions" element={<TermNCondition />} />

              <Route path="/login" element={<Login />} />
              <Route path="/otp" element={<Otp />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/sub-status" element={<SubStatus />} />
              <Route path="/upgrade-plan" element={<UpgradePlan />} />
              <Route path="/unsubscribe" element={<Unsubscribe />} />
              <Route path="/" element={<LandingPage />} />
              {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
            </Routes>
          </Router>
        ) : (
          <div className="text-xl font-bold text-white flex  h-screen items-center justify-center text-center">
            your internet is not working
          </div>
        )
      }
    </div >
  );
}

export default App;
