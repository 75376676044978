import { HEADERS, countryCode, apiUrl } from "../config/wapConfig";

const checkSubcription = (msisdn) => {
  const body = {
    operatorId: "zain_iraq",
    userId: countryCode.substring(1) + msisdn,
  };
  return FETCH_PROMISE(apiUrl.CHECK_SUBSCRIPTION,"POST", body);
};

const sendOtp = (msisdn) => {
  const body = {
    operatorId: "zain_iraq",
    userId: countryCode.substring(1) + msisdn,
  };
  return FETCH_PROMISE(apiUrl.SEND_OTP,"POST", body);
};
const sendPincode = (msisdn) => {
  const body = {
    operatorId: "zain_iraq",
    userId: countryCode.substring(1) + msisdn,
  };
  return FETCH_PROMISE(apiUrl.SEND_PINCODE,"POST", body);
};

const otpVerify = (msisdn,otp) => {
  const body = {
    operatorId: "zain_iraq",
    userId: countryCode.substring(1) + msisdn,
    otp: otp
  };
  return FETCH_PROMISE(apiUrl.OTP_VERIFY,"POST", body);
};

const pincodeVerify = (msisdn,otp) => {
  const body = {
    operatorId: "zain_iraq",
    userId: countryCode.substring(1) + msisdn,
    otp: otp
  };
  return FETCH_PROMISE(apiUrl.PINCODE_VERIFY,"POST", body);
};

const subscribe = (msisdn) => {
  const body = {
    operatorId: "zain_iraq",
    userId: countryCode.substring(1) + msisdn,
  };
  return FETCH_PROMISE(apiUrl.SUBSCRIBE,"POST", body);
};

function FETCH_PROMISE(url, method = "POST", body = null) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method,
      body: JSON.stringify(body),
      headers: HEADERS,
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export {
  checkSubcription,
  sendOtp,
  sendPincode,
  otpVerify,
  pincodeVerify,
  subscribe,
};
