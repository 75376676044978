import { useState } from "react";
import { POST } from "../Shared/Axios";

const useApiCall = (url, data = {}) => {
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(false)

  const apiCall = () => {
  
    setLoading(true)
    POST(url, data)
      .then((data) => { setApiData(data); setLoading(false); console.log(data); })
      .catch((err) => console.log(err));
  }
  console.log(apiData, "apiData");
  return [apiData, apiCall, loading];
};
export default useApiCall;