const BASE_URL = "https://swip4winapiv1.bngrenew.com:5081/swipe4win";

const apiUrl = {
  SEND_OTP: BASE_URL + "/otp/push/smszn",
  OTP_VERIFY: BASE_URL + "/otp/verify/smszn",
  SUBSCRIBE: BASE_URL + "/subscribe",
  CHECK_SUBSCRIPTION: BASE_URL + "/user/subscription",
  SEND_PINCODE: BASE_URL + "/otp/zain/send-pincode",
  PINCODE_VERIFY: BASE_URL + "/otp/zain/verify-pincode",
};

const HEADERS = {
  defaultlanguage: "ar",
  timezone: "+3",
  operatorid: "zain_iraq",
  "Content-Type": "application/json",
  accept: "application/json",
};

const countryCode = "+964";
const heURl = "http://www.social-sms.com/iq-dcb/HE/v1.2/oneclick/sub.php?serviceId=746&spId=60&shortcode=3368&uniqid=26c5bfef91471b97820b87c3e6cb21de";

export { HEADERS, countryCode, apiUrl, heURl };
