import React from "react";
import { Header } from "..";
import "./faq.scss";

const Faq = () => {
  // const getLang = window.localStorage.getItem("lang");
  const getLang = "ar";
  return (
    <div className="main">
      <Header />
      <section className=" faqs-container">
        <div class="">
          <div className="text-white">
            <h1
              className={`text-start text-xl font-bold pr-2  ${
                getLang == "ar" ? "arabic-lang" : ""
              }`}
            >
              {getLang === "en" && "Frequently Asked Questions"}
              {getLang === "fr" && "Frequently Asked Questions"}
              {getLang === "ar" && "أسئلة مكررة"}
            </h1>
            <div className="py-1 px-4 list-data">
              {/* <h6
                className={`font-bold  ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "How I subscribe?"}
                {getLang === "fr" && "Comment m'abonner ?"}
                {getLang === "ar" && "كيف أشترك؟"}
              </h6>
              <p className={`mt-1  ${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "en" &&
                  " You can subscribe to ' Swipe & Win' service by:"}
                {getLang === "fr" &&
                  "Vous pouvez souscrire au service 'Swipe & Win' en :"}
                {getLang === "ar" &&
                  `يمكنك الاشتراك في خدمة "إلعب وإربح" من خلال:`}
              </p> */}
              <ul
                className={` list-inside	 list-disc pl-3  ${
                  getLang == "ar" ? "arabic-lang" : ""
                }`}
              >
                <li>
                  {" "}
                  {getLang === "ar" &&
                    `ما هي خدمة تحدي المعرفة؟
                     هي عبارة عن خدمة لتختبر وتنمي معلوماتك العامة مع إتاحة الفرصة لإختبار هذه المعلومات عن طريق المشاركة في تحدي المعرفة والإجابه على مجموعة الاسئلة اليومية.`}
                </li>
                <li className=" mt-1">
                  {getLang === "en" && "Following the link via XXX app menu"}
                  {getLang === "fr" &&
                    "Suivre le lien via le menu de l'application XXX"}
                  {getLang === "ar" &&
                    `كيف اشترك في الخدمة؟ يمكنك الاشتراك عن طريق ارسال DT ل 3368 في رسالة نصية`}
                </li>
                <li className=" mt-1">
                  {getLang === "en" && "Visit website"}
                  {getLang === "fr" && "Visitez le site Web"}
                  {getLang === "ar" &&
                    `كيف الغي اشتراكي في الخدمة؟ يمكنك الغاء الاشتراك عن طريق ارسال D2 ل 3368 في رسالة نصية.`}
                </li>
                {/* <li className=" mt-1">
                  {getLang === "en" && "Dial XXX from your mobile number"}
                  {getLang === "ar" && "اطلب XXX من رقم هاتفك المحمول"}
                  {getLang === "fr" &&
                    "Composez XXX depuis votre numéro de mobile"}
                </li>
                <li className=" mt-1">
                  {getLang === "en" &&
                    "Send SUB to XXX from your mobile number"}
                  {getLang === "fr" &&
                    "Envoyez SUB à XXX depuis votre numéro de mobile"}
                  {getLang === "ar" && "أرسل SUB إلى XXX من رقم هاتفك المحمول"}
                </li> */}
              </ul>
              {/* 
              <h6
                className={`font-bold  mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "What is the duration of the service?"}
                {getLang === "fr" && "Quelle est la durée de la prestation ?"}
                {getLang === "ar" && "ما هي مدة الخدمة؟"}
              </h6>
              <p className={`mb-1 ${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "en" && "The service has annual duration."}
                {getLang === "fr" && "Le service a une durée annuelle."}
                {getLang === "ar" && "مدة الخدمة سنوية."}
              </p>

              <h6
                className={`font-bold  mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "What are the prizes?"}
                {getLang === "fr" && "Quels sont les prix ?"}
                {getLang === "ar" && "ما هي الجوائز؟"}
              </h6>
              <p className={`mb-0  ${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "en" && "Swipe & Win service gives away:"}
                {getLang === "fr" && "Le service Swipe & Win offre :"}
                {getLang === "ar" && "تمنح خدمة إلعب وإربح:"}
              </p>
              <ul
                className={` pl-3 list-inside list-disc  ${
                  getLang == "ar" ? "arabic-lang" : ""
                }`}
              >
                <li className=" mt-1">
                  {getLang === "en" &&
                    "XXMB once upon scoring 10 or more correct questions"}
                  {getLang === "fr" &&
                    "XXMB une fois après avoir obtenu 10 bonnes questions ou plus"}
                  {getLang === "ar" &&
                    "XXMB مرة واحدة عند تسجيل 10 أسئلة صحيحة أو أكثر"}
                </li>
                <li className=" mt-1">
                  {getLang === "en" && "XXX USD in Cash every day"}
                  {getLang === "fr" && "XXX USD en espèces chaque jour"}
                  {getLang === "ar" && "XXX دينار ليبي كل يوم"}
                </li>
                <li className=" mt-1">
                  {getLang === "en" && "XXX USD in Cash every week"}
                  {getLang === "fr" && "XXX USD en espèces chaque semaine"}
                  {getLang === "ar" && "XXX دينار ليبي كل أسبوع"}
                </li>
                <li className=" mt-1">
                  {getLang === "en" && "XXX USD in Cash every month"}
                  {getLang === "fr" && "XXX USD en espèces chaque mois"}
                  {getLang === "ar" && "XXX دينار ليبي كل شهر"}
                </li>
              </ul>

              <h6
                className={`font-bold mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && " If I win, how will I be informed?"}
                {getLang === "fr" && "Si je gagne, comment serais-je informé ?"}
                {getLang === "ar" && "إذا فزت ، كيف سيتم إعلامي؟"}
              </h6>
              <p className={`${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "en" &&
                  "The winner will be contacted by Operator in the next days as to explain the process needed. So do not worry, just keep your phone on."}
                {getLang === "fr" &&
                  "Le gagnant sera contacté par l'opérateur dans les prochains jours afin d'expliquer le processus nécessaire. Alors ne vous inquiétez pas, gardez simplement votre téléphone allumé."}
                {getLang === "ar" &&
                  "سيتم الاتصال بالفائز من قبل المشغل في الأيام القادمة لشرح العملية المطلوبة. لذلك لا تقلق ، فقط احتفظ بهاتفك قيد التشغيل."}
              </p>

              <h6
                className={`font-bold mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "How many times may I win each prize?"}
                {getLang === "fr" &&
                  "Combien de fois puis-je gagner chaque prix ?"}
                {getLang === "ar" && "كم مرة يمكنني الفوز بكل جائزة؟"}
              </h6>
              <p className={` ${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "en" &&
                  "Each user is eligible to win the daily prize unlimited times and rest prizes only once."}
                {getLang === "fr" &&
                  "Chaque utilisateur est éligible pour gagner le prix quotidien un nombre illimité de fois et les prix de repos une seule fois."}
                {getLang === "ar" &&
                  "كل مستخدم مؤهل للفوز بالجائزة اليومية مرات غير محدودة وبقية الجوائز مرة واحدة فقط."}
              </p>

              <h6
                className={`font-bold mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "What are the Swipe & Win game rules?"}
                {getLang === "fr" &&
                  "Quelles sont les règles du jeu Swipe & Win ?"}
                {getLang === "ar" && "ما هي قواعد لعبة التمرير والفوز؟"}
              </h6>
              <ul
                className={` list-disc pl-3 ${
                  getLang === "ar" ? "arabic-lang list-inside " : ""
                }`}
              >
                <li className=" mt-1">
                  {getLang === "en" && "Each game lasts for 55 seconds."}
                  {getLang === "fr" && "Chaque partie dure 55 secondes."}
                  {getLang === "ar" && "كل لعبة تستمر لمدة 55 ثانية."}
                </li>
                <li className=" mt-1">
                  {getLang === "en" &&
                    "Each question is consisted of a statement and a photo."}
                  {getLang === "fr" &&
                    "Chaque question est composée d'une affirmation et d'une photo."}
                  {getLang === "ar" && "يتكون كل سؤال من بيان وصورة."}
                </li>
                <li className=" mt-1">
                  {getLang === "en" &&
                    "User has to Swipe right for Correct or left for Wrong."}
                  {getLang === "fr" &&
                    "L'utilisateur doit balayer vers la droite pour corriger ou vers la gauche pour se tromper."}
                  {getLang === "ar" &&
                    "يجب على المستخدم التمرير لليمين للتصحيح أو من اليسار إلى الخطأ."}
                </li>
                <li className=" mt-1">
                  {getLang === "en" &&
                    "Be careful! Every wrong reply deducts 5 seconds from the remaining time!"}
                  {getLang === "fr" &&
                    "Fais attention! Chaque mauvaise réponse déduit 5 secondes du temps restant !"}
                  {getLang === "ar" &&
                    "كن حذرا! كل رد خاطئ يقتطع 5 ثوان من الوقت المتبقي!"}
                </li>
                <li className=" mt-1">
                  {getLang === "en" &&
                    "During a game round, special questions may also be served such as bronze, silver and golden ones."}
                  {getLang === "fr" &&
                    "Lors d'un tour de jeu, des questions spéciales peuvent également être posées telles que les questions bronze, argent et or."}
                  {getLang === "ar" &&
                    "أثناء جولة اللعبة ، يمكن أيضًا طرح أسئلة خاصة مثل الأسئلة البرونزية والفضية والذهبية."}
                </li>
              </ul>

              <h6
                className={`font-bold mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "Does my Swipe & Win credits expire?"}
                {getLang === "fr" && "Mes crédits Swipe & Win expirent-ils ?"}
                {getLang === "ar" &&
                  "هل تنتهي صلاحية رصيد السحب والفوز الخاص بي؟"}
              </h6>
              <p className={`${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "en" &&
                  "Both awarded and purchased Swipe & Win credits expire at the end of calendar day if not consumed until then."}
                {getLang === "fr" &&
                  "Les crédits Swipe & Win attribués et achetés expirent à la fin de la journée calendaire s'ils n'ont pas été consommés jusque-là."}
                {getLang === "ar" &&
                  "تنتهي صلاحية أرصدة إلعب وإربح الممنوحة والمشتراة في نهاية يوم التقويم إذا لم يتم استهلاكها حتى ذلك الحين."}
              </p>

              <h6
                className={`font-bold  mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" &&
                  "I consume my daily credit; can I add more?"}
                {getLang === "fr" &&
                  "je consomme mon crédit journalier ; puis-je ajouter plus?"}
                {getLang === "ar" &&
                  "أنا أستهلك رصيدي اليومي ؛ هل يمكنني إضافة المزيد؟"}
              </h6>
              <p className={`${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "en" &&
                  ` You can purchase a bundle of 3 Swipe & Win credits only for XXX USD upon consuming your available daily credit. Each user may play up to 5 extra rounds per day.`}
                {getLang === "fr" &&
                  "Vous pouvez acheter un lot de 3 crédits Swipe & Win uniquement pour XXX USD en consommant votre crédit quotidien disponible. Chaque utilisateur peut jouer jusqu'à 5 parties supplémentaires par jour."}
                {getLang === "ar" &&
                  "يمكنك شراء حزمة من 3 أرصدة إلعب وإربح مقابل  XX دينار ليبي عند استهلاك رصيدك اليومي المتاح. يمكن لكل مستخدم لعب ما يصل الي 5 جولات إضافية في اليوم."}
              </p>

              <h6
                className={`font-bold mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "How can I unsubscribe?"}
                {getLang === "fr" && "Comment puis-je me désinscrire ?"}
                {getLang === "ar" && "كيف يمكنني إلغاء الاشتراك؟"}
              </h6>
              <p className={`${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "en" &&
                  `To cancel your ' Swipe & Win' subscription, send 'STOP' to the short code XXX.`}
                {getLang === "fr" &&
                  `Pour résilier votre abonnement ' Swipe & Win', envoyez 'STOP' au short code XXX.`}
                {getLang === "ar" &&
                  `لإلغاء اشتراك "إلعب وإربح" ، أرسل "إيقاف" إلى الرمز القصير XXX.`}
              </p>

              <h6
                className={`font-bold mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" &&
                  ` I’m not an “Operator” subscribe; may I participate to the service?`}
                {getLang === "fr" &&
                  `Je ne suis pas un abonnement "Opérateur" ; puis-je participer au service ?`}
                {getLang === "ar" &&
                  `أنا لست مشتركًا في "مشغل" ؛ هل يمكنني الاشتراك في الخدمة`}
              </h6>
              <p className={` ${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "en" &&
                  "No, if you are not an xxx subscriber unfortunately you cannot subscribe to the service."}
                {getLang === "fr" &&
                  "Non, si vous n'êtes pas abonné xxx, vous ne pouvez malheureusement pas vous abonner au service."}
                {getLang === "ar" &&
                  "لا ، إذا لم تكن مشتركًا في xxx للأسف لا يمكنك الاشتراك في الخدمة."}
              </p> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Faq;
