import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, DecodeBase64, Header, Loader, Modal } from "../Components";
import congImage from "../Assets/Images/Congratulations.png";
import {page,event,key} from "../events/event"
import {SendEvent} from "../events/eventPush"

function ResultInfo({ end }) {
  const location = useLocation();
  const { state } = location;
  const getLang = window.localStorage.getItem("lang");
  const [loading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const uid = window.localStorage.getItem("user-msisdn");
  const wap = localStorage.getItem("wap");

  return (
    <div
      className="main h-screen"
      style={{ backgroundColor: "#4b0083", width: "100%" }}
    >
      <Header text={"result"} />
      {loading && <Loader />}
      <div className=" mt-1 text-white">
        <div
          className={`flex flex-col items-center justify-center ${
            getLang === "ar" ? "arabic-lang" : ""
          }`}
        >
          <img
            src={congImage}
            alt="right-icon"
            width="40%"
            className="mt-12 mb-8"
          />
          <h2
            className={`text-2xl text-center font-bold mt-8 text-3xl text-white ${
              getLang === "ar" ? "arabic-lang" : ""
            }`}
          >
            {end ? DecodeBase64(end?.greet?.title) : ""}
          </h2>

          <p
            className={`mt-8 mb-12 text-md text-center ${
              getLang === "ar" ? "arabic-lang" : ""
            }`}
          >
            {end ? DecodeBase64(end?.greet?.subtitle) : ""}
          </p>

          {/* <div
            className={`text-center mt-2 ${getLang === "ar" ? "arabic-lang" : ""
              }`}
          >
            {state.propsData.quizEnd
              ? DecodeBase64(state?.propsData?.quizEnd?.greet?.next)
              : ""}
          </div> */}

          {end.greet ? (
            <Button
              name={DecodeBase64(end.greet.nextButton)}
              className={
                `button-theme bg-[#ffde18] px-4 py-2 mt-40 rounded-full text-yellow-900 font-bold `
              }
              handleClick={() => {
                navigate("/home");
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ResultInfo;
