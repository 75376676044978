import React, { useEffect } from "react";
import "./Nav.scss";
import { Link } from "react-router-dom";
import { API_SWIP_URLS } from "../../Shared/Constant";
import { DecodeBase64 } from "..";
import useApiCall from "../../Utilies/useApiCall";
import { operatorId } from "../../config";

const SideBar = ({setShowNav}) => {
  //  const [menu, setMenu] = useState("")
  const uid = window.localStorage.getItem("user-msisdn");
  const [config, configApiCall, loading] = useApiCall(API_SWIP_URLS.Config, {
    userId: uid,
    operatorId: operatorId,
  });
  useEffect(() => {
    configApiCall();
  }, []);
  const handleCloseSidebar = () => {
    // Implement your logic to close the sidebar here
    setShowNav(false)
  };

  console.log(config, "menu");
  return (
    <nav className="navigation pl-4 ">
      <div>
        {config?.menu?.length &&
          config?.menu?.map((items, i) => {
            return (
              <div className="mt-1 mb-1" key={i}>
                <Link to={items?.link} className="text-[#4b0083]" onClick={handleCloseSidebar} onBlur={handleCloseSidebar} >
                  {DecodeBase64(items?.title)}
                </Link>
              </div>
            );
          })}
      </div>
    </nav>
  );
};
export default SideBar;
