import React,{useRef,  useCallback} from "react";
import TinderCard from "react-tinder-card";
import { POST } from "../Shared/Axios";
import { API_SWIP_URLS } from "../Shared/Constant";
import { operatorId } from "../config";

const CardTemp = ({
  questions,
  startData,
  childRefs,
  setCurrentIndex,
  currentIdex,
  handleImageLoad,
  setCount,
  setAnsApiCount
}) => {
  const uid = window.localStorage.getItem("user-msisdn");
  const swipedCards = useRef([]);

  const swiped = useCallback(
    (direction, index) => {
      setCount(10);
      setCurrentIndex(index - 1);
      console.log(direction, "dir");

      if (swipedCards.current.includes(index)) {
        return; // Card has already been swiped, ignore the swipe event
      }

      swipedCards.current.push(index);

    if (direction === "left") {
      const data = {
        userId: uid && uid,
        sessionId: startData.sessionId,
        operatorId: operatorId,
        useragent:
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:106.0) Gecko/20100101 Firefox/106.0",
        answers: [
          {
            answer: false,
            questionId: questions[index]?.questionId,
            timeout: 1,
          },
        ],
      };
      POST(API_SWIP_URLS.Answer, data)
        .then((res) => {
          setAnsApiCount(prev=>prev+1)
        })
        .catch((err) => err);
    } else if (direction === "right") {
      const data = {
        userId: uid && uid,
        sessionId: startData.sessionId,
        operatorId:operatorId,
        useragent:
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:106.0) Gecko/20100101 Firefox/106.0",
        answers: [
          {
            answer: true,
            questionId: questions[index]?.questionId,
            timeout: 0,
          },
        ],
      };
      POST(API_SWIP_URLS.Answer, data)
        .then((res) => {
          setAnsApiCount(prev=>prev+1)
        })
        .catch((err) => err);
    }
    else if (direction === "up") {
          const data = {
            userId: uid && uid,
            sessionId: startData?.sessionId,
            operatorId: operatorId,
            useragent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:106.0) Gecko/20100101 Firefox/106.0",
            answers: [
              {
                answer: false,
                questionId: questions[index]?.questionId,
                timeout: 1,
              },
            ],
          };
          POST(API_SWIP_URLS.Answer, data)
            .then((res) => {
              setAnsApiCount(prev=>prev+1)
            })
            .catch((err) => err);
        }
  },
  [questions, startData.sessionId, setCurrentIndex, setCount]
  );
console.log(currentIdex,"currentIdex");
  return (
    <div>
      {questions.length &&
        questions?.map((item, idx) => {
          return (
            <div className="flex column">
              <TinderCard
                ref={childRefs[idx]}
                swipeRequirementType={"position"}
                className="swip_cards"
                preventSwipe={["down"]}
                onSwipe={(dir) => swiped(dir, idx)}
                swipeThreshold={150}
                key={idx}
              >
                <div className=" w-11/12 mx-auto rounded-lg">
                  <img
                    src={item?.imageUrl}
                    alt={item?.questionText}
                    onLoad={handleImageLoad}
                    className="rounded-lg"
                    height="100%"
                    width="100%"
                  />
                  {/* <p className="font-bold text-base text-white px-1 mb-2 pt-1">
                  {DecodeBase64(item?.questionText)}
                </p> */}
                </div>
                
              </TinderCard>
            </div>
          );
        })}
    </div>
  );
};

export default React.memo(CardTemp);
