import React from 'react'

function Header() {
  return (
    <div className="h-20 rounded-b-3xl bg-lightPurple flex justify-center items-center">
        <img className='w-28 h-10' src={require('../../Assets/wap/zainIraqS4W/app_text.png')}></img>
    </div>
  )
}

export default Header