import React from "react";
import "./term.scss";
import { Header } from "..";

const TermNCondition = () => {
  // const getLang = window.localStorage.getItem("lang");
  const getLang = "ar";
  return (
    <div className="main">
      <Header />
      <section className="terms-container">
        <div className=" ">
          <div className="text-white">
            <h1
              className={`text-start text-xl font-bold pr-2  ${
                getLang === "ar" ? "arabic-lang" : ""
              }`}
            >
              {getLang === "en" && "TERMS AND CONDITIONS"}
              {getLang === "fr" && "TERMES ET CONDITIONS"}
              {getLang === "ar" && "الشروط والأحكام"}
            </h1>
            <div>
              <h6
                className={`font-bold  mt-1  ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" &&
                  "For conducting the content service “Swipe & Win”"}
                {getLang === "fr" &&
                  `Pour la gestion du service de contenu "Swipe & Win"`}
                {getLang === "ar" &&
                  `باشتراكك في خدمة تحدي المعرفة، انك توافق على الشروط والاحكام التالية:
`}
              </h6>
              <ul className={`pl-4 ${getLang === "ar" ? "arabic-lang" : ""}`}>
                <li className="pl-4  mt-1">
                  {getLang === "ar" && " سعر الخدمة 240 دينار عراقي يومياً"}
                </li>
                <li className="pl-4  mt-1">
                  {getLang === "ar" &&
                    "   سيتم تجديد الاشتراك يومياً وبشكل تلقائي"}
                </li>
                <li className="pl-4  mt-1">
                  {getLang === "ar" &&
                    " يمكنك إلغاء الاشتراك في أي وقت عن خلال إرسال D2 إلى 3368"}
                </li>
                <li className="pl-4  mt-1">
                  {getLang === "ar" &&
                    `   للدعم التقني قم بالتواصل عبر: (mobileapps@blackngreen.com)`}
                </li>
              </ul>
              {/* <ol className= {`pl-4 ${getLang==="ar"?"arabic-lang":""}`}>
                <li className="li-items  mt-1  ">
                  {getLang === "en" && "Available SMS short-codes"}
                  {getLang === "fr" && "Codes abrégés SMS disponibles"}
                  {getLang === "ar" &&
                    "الرموز القصيرة المتاحة للرسائل النصية القصيرة"}
                  <ol className={`pl-4 ${getLang==="ar"?"arabic-lang":""}`}>
                    <li className="pl-4  mt-1  li-items ">
                      {getLang === "en" &&
                        ` The subscription to the “Swipe & Win” content service is available in Arabic and English languages; it has daily cost XXX with 1 day free of charge trial period.`}
                      {getLang === "fr" && "L'abonnement au service de contenu « Swipe & Win » est disponible en langues arabe et anglaise ; il a un coût quotidien de XXX avec une période d'essai gratuite d'un jour."}
                      {getLang === "ar" && "الاشتراك في خدمة المحتوى “إلعب وإربح” متاح باللغتين العربية والإنجليزية. تبلغ التكلفة اليومية XXX مع فترة تجريبية مجانية لمدة يوم واحد."}
                    </li>
                    <li className="pl-4  mt-1  li-items ">
                      {getLang === "en" &&
                        `All of the SMS sent to the above short-code are free of charge`}
                      {getLang === "fr" && "Tous les SMS envoyés au numéro court ci-dessus sont gratuits"}
                      {getLang === "ar" && "جميع الرسائل القصيرة المرسلة إلى الرمز المختصر أعلاه مجانية"}
                    </li>
                    <li className="pl-4  mt-1  li-items ">
                      {getLang === "en" &&
                        `Operator subscribers may opt in to the subscription service “Swipe & Win” by:`}
                      {getLang === "fr" && "Les abonnés Opérateurs peuvent souscrire au service d'abonnement « Swipe & Win » en :"}
                      {getLang === "ar" && `يمكن لمشتركي المشغل الاشتراك في خدمة الاشتراك "إلعب وإربح" عن طريق:`}
                    </li>
                    <ol className={`pb-6 ${getLang==="ar"?"arabic-lang":""}`}>
                      <li className="pl-4 mt-1 li-items ">
                        {getLang === "en" &&`Sending a valid keyword via SMS to the short-code XXX (please check section 8.2 for keywords table)`}
                        {getLang === "fr" && "Envoi d'un mot-clé valide par SMS au code abrégé XXX (veuillez consulter la section 8.2 pour le tableau des mots-clés)"}
                        {getLang === "ar" && "إرسال كلمة رئيسية صالحة عبر رسالة نصية قصيرة إلى الرمز القصير XXX (يرجى مراجعة القسم 8.2 للحصول على جدول الكلمات الرئيسية)"}
                      </li>
                      <li className="pl-4  mt-1  li-items ">
                        {getLang === "en" && " Dialing the USSD command XXX"}
                        {getLang === "fr" && "Composition de la commande USSD XXX"}
                        {getLang === "ar" && "طلب أمر USSD XXX"}
                      </li>
                      <li className="pl-4   mt-1 li-items ">
                        {getLang === "en" &&
                          `  By following the “Swipe & Win” link via “XXX” app`}
                        {getLang === "fr" && `En suivant le lien "Swipe & Win" via l'application "XXX"`}
                        {getLang === "ar" && `باتباع رابط "إلعب وإربح" عبر تطبيق "XXX"`}
                      </li>
                      <li className="pl-4  mt-1 li-items ">
                        {getLang === "en" &&
                          " following the link XXX and get registered online"}
                        {getLang === "fr" && "en suivant le lien XXX et inscrivez-vous en ligne"}
                        {getLang === "ar" && "باتباع الرابط XXX والتسجيل عبر الإنترنت"}
                      </li>
                    
                    <li className="pl-4  mt-1  li-items ">
                      {getLang === "en" &&
                        ` Upon registration, the users will receive the welcome SMS, 10 points for the upcoming weekly prize and the “Swipe & Win”`}
                      {getLang === "fr" && `Lors de l'inscription, les utilisateurs recevront le SMS de bienvenue, 10 points pour le prochain prix hebdomadaire et le "Swipe & Win"`}
                      {getLang === "ar" && `"عند التسجيل ، سيتلقى المستخدمون رسالة نصية قصيرة ترحيبية ، 10 نقاط للجائزة الأسبوعية القادمة و "إلعب وإربح`}
                    </li>
                    </ol>
                  </ol>
                </li>
              </ol> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default TermNCondition;
