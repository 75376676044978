// clearCache.js
import { database } from './firebaseConfig'; // Adjust the path as necessary
import { ref, onValue } from 'firebase/database';

function clearCache() {
  const appVersionFirebaseRef = ref(database, 'version-zainIraq');
  
  onValue(appVersionFirebaseRef, (snapshot) => {
    const version = snapshot.val();
    const appVersion = localStorage.getItem("appVersion");
    console.log("appVersionFirebase => " + version + " & appVersion => " + appVersion);

    if (appVersion !== version) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      console.log("cache cleared");
      localStorage.setItem("appVersion", version); // Correctly set the new version
      window.location.reload(true); // Reload the page
    }
  });
}

export default clearCache;
