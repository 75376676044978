//export const API_BASE_URL="https://swip4winapiv1.bngrenew.com:5081/swipe4win/"
//export const API_BASE_URL="https://mtnzmb.swipe4win.com/api/"
export const API_BASE_URL = "https://swip4winapiv1.bngrenew.com:5081/swipe4win/"
export const API_SWIP_BASE_URL = "https://swip4winapiv1.bngrenew.com:5081/swipe4win/"
export const API_SWIP_URLS = {
    Config: API_SWIP_BASE_URL + "config",
    Register: API_SWIP_BASE_URL + "register",
    Questions: API_SWIP_BASE_URL + "user/questions",
    Buy: API_SWIP_BASE_URL + "pack/buy",
    Answer: API_SWIP_BASE_URL + "user/question/answer",
    Start: API_SWIP_BASE_URL + "quiz/start",
    End: API_SWIP_BASE_URL + "quiz/end",
    LeaderBoard: API_SWIP_BASE_URL + "user/leaderboard",
    OTP: API_SWIP_BASE_URL + "prelogin/user/generateotp",
    VerifyOTP: API_SWIP_BASE_URL + "prelogin/user/verifyotp",
    DidYouKnow:"https://swipe4win.bngrenew.com:5081/swipe4win/didyouknow/set"
}