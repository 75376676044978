const subStatusData = [
  {
    id: 1,
    type: "thank_you",
    heading: "شكراً لك",
    text1:"تم تقديم طلبك، ستتلقى رسالة نصية قريبًا",
    text2: "",
    icon: require("../../Assets/wap/daretoknowAssets/thank_you_icon.png"),
  },
  {
    id: 2,
    type: "great",
    btn: "العب الان",
    heading: "رائع",
    text1: "لقد اشتركت في 3 فرص يوميًا.",
    text2: "",
    icon: require("../../Assets/wap/daretoknowAssets/correct_tick.png"),
  },
  {
    id: 3,
    type: "activated",
    btn: "التالي",
    heading: "مبروك",
    text1: "أنت مشترك بالفعل في خدمة Daretoknow",
    text2: "",
    icon: require("../../Assets/wap/daretoknowAssets/congratulations_activated_icon.png"),
  },
  {
    id: 4,
    type: "subs_fail",
    heading: "عذراً",
    text1:" لم يتم الاشتراك لان  الرصيد غير كاف!",
    text2: "الرجاء إعادة الشحن والمحاولة مرة أخرى",
    icon: require("../../Assets/wap/daretoknowAssets/sorry_low_balance_icon.png"),
  },
  {
    id: 5,
    type: "technical_error",
    btn: "حاول مرة أخرى",
    heading: "عذراً",
    text1: "هناك خطأ تقني، يرجى المحاولة مرة اخرى",
    text2: "",
    icon: require("../../Assets/wap/daretoknowAssets/sorry_technical_error_icon.png"),
  },
  {
    id: 6,
    type: "plan_upgrade",
    btn: "نعم",
    heading: "تهانينا",
    text1: "تم ترقية حزمة اشتراكك.",
    text2: "",
    icon: require("../../Assets/wap/zainIraqS4W/thums_up.png"),
  },
  {
    id: 7,
    type: "success",
    btn: "التالي",
    heading: "تهانينا",
    text1:"انت الآن مشترك في خدمة تحدي المعرفة ليوم مجانا، ثم ب 240 دينار عراقي في اليوم.",
    text2: "",
    icon: require("../../Assets/wap/daretoknowAssets/congratulations_tick_icon.png"),
  },
  {
    id: 8,
    type: "technical_error",
    btn: "",
    heading: "عزيزي المشترك يرجى المحاولة بعد 24 ساعة",
    text1: "",
    text2: "",
    icon: require("../../Assets/wap/daretoknowAssets/sorry_low_balance_icon.png"),
  },
];

export { subStatusData };
