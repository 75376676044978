import React, { useState } from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';

const OtpInput = ({ length, onComplete, setOtp, otp }) => {

    const [showOtp, setShowOtp] = useState(false);

    const handleChange = (event) => {
        const { value } = event.target;
        setOtp(value.slice(0, length));
    
        if (value.length === length) {
            setOtp(value);
        }
    };

    const toggleShowOtp = () => {
        setShowOtp(!showOtp);
    };

    return (
        <div className="flex justify-center items-center space-x-2">
            <div className="relative flex items-center">
                <input
                    type={showOtp ? "text" : "password"}
                    value={otp}
                    onChange={handleChange}
                    maxLength={length}
                    placeholder=''
                    className="w-64 h-12  text-xl text-center bg-inputBg rounded-lg focus:border-blue-800 focus:outline-none"
                    style={{
                        letterSpacing: '20px'
                    }}
                />
                <div className="absolute right-4 top-1 text-2xl cursor-pointer" onClick={toggleShowOtp}>
                    {showOtp ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </div>
            </div>
        </div>
    );
};

export default OtpInput;
