import React from "react";
import "./button.css";

function Button({ name = "", handleClick = () => {}, className, img ,disabled}) {
  return (
    <button disabled={disabled} className={className ? className : "button-theme p-2 rounded-3xl"} onClick={handleClick}>
      {img && <img src={img} /> }
      <span>{name}</span>
    </button>
  );
}

export default Button;
