const event = {
    open: "open",
    loginClick :"login_click",
    checksubMsisdnReq : "checksub_msisdn_req",
    checksubMsisdnRes: "checksub_msisdn_res",
    sendOtpReq: "send_otp_req",
    sendOtpRes: "send_otp_res",
    sendPincodeReq: "send_pincode_req",
    sendPincodeRes : "send_pincode_res",
    verifyOtpClick : "verify_otp_click",
    verifyPincodeReq: "verify_pincode_req",
    veriifyPincodeRes: "verify_pincode_res",
    verifyOtpReq: "verify_otp_req",
    verifyOtpRes: "verify_otp_res",
    registerReq: "register_req",
    registerRes: "register_res",
    buyPackReq: "buy_pack_req",
    buyPackRes: "buy_pack_res"
}

const page = {
    subscription: "subscription",
    login: "login",
    otp: "otp",
    subStatus: "subStatus",
    termsAndCond: "termsAndCond",
    privacyPolicy: "privacyPolicy",
    faq: "faq",
    upgrade: "upgrade",
    unsubcribe: "unsubscribe",
    landing: "landing",
    buyMore: "buyMore",
    result: "result",
    home: "home",
    info: "info"
}

const key = {
    page: "page",
    event : "event",
    status : "status",
    msisdn: "msisdn",
    otp:"otp",
    uuid: "uuid",
    uid: "uid",
    redirectedUrl: "redirectedUrl"
}

export {event,page,key}