import React, { useEffect, useState } from "react";
import "./../Assets/Styles/Styles.css";
import { Logo } from "../Utilies/ImageExports";
import { useNavigate } from "react-router-dom";
import { POST } from "../Shared/Axios";
import { API_SWIP_URLS } from "../Shared/Constant";
import RightArrow from "../Assets/Images/rightArrow.png";
import LeftArrow from "../Assets/Images/leftArrow.png";
import ResultInfo from "../Swip4Win/ResultInfo";
import DisableImage from "../Assets/Images/disableArrow.png";
import Loader from "./Loader";
import { DecodeBase64 } from "./DecodeBase64";
// Example slides data
// const slides = [
//   {
//     title: "First Slide",
//     content: "This is the first slide",
//     imageUrl: "https://via.placeholder.com/600x400?text=First+Slide",
//   },
//   {
//     title: "Second Slide",
//     content: "This is the second slide",
//     imageUrl: "https://via.placeholder.com/600x400?text=Second+Slide",
//   },
//   {
//     title: "Third Slide",
//     content: "This is the third slide",
//     imageUrl: "https://via.placeholder.com/600x400?text=Third+Slide",
//   },
//   // Add more slides as needed
// ];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState([]);
  const [endData, setendData] = useState([]);
  const [loadingTime, setLoadingTime] = useState(true);
  const [loading, setLoading] = useState(false);
  const [endSlide, setEndSlide] = useState(false);
  const navigate = useNavigate();
  const handleImageLoad = () => {
    setLoadingTime(false);
  };
  const uid = window.localStorage.getItem("user-msisdn");
  console.log("inside question");
  useEffect(() => {
    setLoading(true);

    const data = {
      operatorId: "zain_iraq",
      questions: true,
      userId: uid,
    };
    POST(API_SWIP_URLS.DidYouKnow, data)
      .then((res) => {
        if(res.status!=="FAILURE"){
          setLoading(false);
          setendData(res);
          setSlides(res.questions);
        }
        if(res.status=="FAILURE"){
          setLoading(false);
          navigate("/home")
        }
      })
      .catch((err) => {
        
        setLoading(false);
        navigate("/home")
      });
  }, []);
  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
    if (currentSlide === slides.length - 1) {
      console.log("iinside end slid");
      setEndSlide(true);
    }
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className=" ">
      {loading ? <Loader /> : 
      <>
      {!endSlide && (
        <div className="main swipe_bg w-full h-screen overflow-hidden">
          <div className="swip-logo p-2 flex justify-between items-center">
            <img src={LeftArrow} alt="back" onClick={() => navigate("/home")} />
            <div className="flex justify-center">
              {/* This will push the logo to the center */}
              <img
                src={Logo}
                onLoad={handleImageLoad}
                alt="logo"
                width="150"
                onClick={() => navigate("/home")}x
              />
            </div>
            <div></div> {/* This is an empty div to adjust space if needed */}
          </div>

          <div>
            <div className="swip_cards">
              <div className=" w-11/12 mx-auto rounded-lg">
                {slides.length > 0 ? (
                  <img
                    src={slides[currentSlide].imageUrl}
                    alt={slides[currentSlide].imageUrl}
                    className="rounded-lg mt-36 ml-8"
                    height="80%"
                    width="80%"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div dir="rtl" className={`text-white font-bold pb-1 px-6 text-center mt-4 `}>
              {slides.length > 0 ? DecodeBase64(slides[currentSlide].questionText) : ""}
            </div>
          </div>
          <div className="absolute bottom-1 left-1/2 -translate-x-1/2  flex flex-col justify-center items-center max-w-[500px] w-full">
            <div className="m-auto btns-bg">
              <div className="flex mt-1 m-auto ">
                <button
                  className={`cursor-pointer mb-4 mr-8 ${
                    currentSlide === 0 ? "bg-grey-400" : ""
                  } `}
                  onClick={prevSlide}
                  disabled={currentSlide === 0} // Disable if first slide
                >
                  {currentSlide === 0 ? (
                    <img src={DisableImage} />
                  ) : (
                    <img src={LeftArrow} width={"100%"} height={"100%"} />
                  )}
                </button>
                <button
                  className="ml-8 cursor-pointer mb-4"
                  onClick={nextSlide}
                  //  disabled={currentSlide === slides.length - 1} // Disable if last slide
                >
                  <img width={"100%"} height={"100%"} src={RightArrow} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {endSlide ? <ResultInfo end={endData} /> : ""}
      </>}
    </div>
  );
};

export default Slider;
