export const injectScript = () => {
    // First script: Load gtag.js asynchronously
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16584996091';
    document.head.appendChild(gtagScript);

    // Second script: Initialize gtag and fire conversion event
    const gtagInitScript = document.createElement('script');
    gtagInitScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-16584996091');
    `;
    document.head.appendChild(gtagInitScript);

    //Thrid script :Conversion script
    const conScript=document.createElement('script');
    conScript.innerHTML=`
        gtag('event', 'conversion', {'send_to': 'AW-16584996091/k0h3CIuEqrQZEPvpq-Q9'});`
    
    document.head.appendChild(conScript);
};
