import React, { useState } from "react";
import { swipLogo, Menu, Close, WhiteMenuIcon, Back, Logo } from "../Utilies/ImageExports";
import { useNavigate, useParams , useLocation} from "react-router-dom";
import SideBar from "./SideBar/Sidebar";
import "./header.css";


function Header({ text = "" }) {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);
  const location = useLocation();

// const {url} = useParams()
console.log("location", location.pathname);
  const onHandleNav = () => {
    setShowNav(!showNav);

    // setTimerStop(!timerStop);

  };
  console.log(text);
  return (
    <>
      <div className={`header ${text == "" ? "bg-white rounded-b-2xl" : text == "leaderboard" ? "bg-[#4b0083]" : "bg-[#9876ff] rounded-b-2xl"} `}>
      { text=="leaderboard"&& <div className="header_icon ml-2">
          <span className="">
            <img src={Back} alt="back" onClick={()=>navigate(-1)}/>
          </span>
        </div>}
       {text !== "leaderboard" ? <div className="header_icon_Swip">
          <span className={`logo_main `}>
            <img
              src={text==""?Logo:swipLogo}
              alt="SwipenWin"
              className={`${text == "leaderboard"?"":"ml-28"}`}
              width="120"
              onClick={() => navigate("/home")}
            />
          </span>
        </div>:""}
        <div className="header_icon">

          <span>
            {text == "leaderboard" ? <img src={WhiteMenuIcon} alt="menu " onClick={() => onHandleNav()} /> : <img src={Menu} alt="Menu" width="36" onClick={() => onHandleNav()} />}
          </span>
        </div>
      </div>
      <div
        className={`navbar ${showNav
            ? "showNav bg-white"
            : "hideNav"
          }`}
      >
        <img src={Close} alt="close" onClick={onHandleNav} />
        {/* <p
          className="absolute pl-5 pr-5 pb-3 pt-3 font-bold mt-2 ml-2 bg-[#9876ff] rounded-full text-white cursor-pointer"
          onClick={onHandleNav}
        >
          X
        </p> */}
        <SideBar setShowNav={setShowNav}/>
      </div>
    </>
  );
}

export default Header;
