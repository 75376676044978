import React from "react";
import { Header } from "..";
import "./howToPlay.scss";

const HowToPlay = () => {
  // const getLang = window.localStorage.getItem("lang");
  const getLang = "ar";
  return (
    <div className="main">
      <Header />
      <section className="">
        <div className="">
          <div class="text-white">
          <div className="mt-4 mb-2">
            {/* <h1
              className={`text-center text-xl font-bold  `}
            >
              {getLang === "en" && "SWIPE & WIN"}
              {getLang === "fr" && "GLISSEZ ET GAGNEZ"}
          {"أجب بسرعة على الاسئلة وإربح جوائز قيمة"}
            </h1> */}
            </div>
            <div>
              <h6
                className={`font-bold flex items-center justify-start ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "HOW TO PLAY:"}
                {getLang === "fr" && "COMMENT JOUER:"}
                {getLang === "ar" &&"طريقة اللعب"}{" "}
              </h6>
              <ul
                className={` pl-3 list-disc ${
                  getLang === "ar" ? "arabic-lang mr-4 pr-4 " : ""
                }`}
              >
                <li className="pt-2">
                  {getLang === "en" &&
                    "Subscribe to Swipe & Win, Play the game and claim the prizes."}
                  {getLang === "fr" &&
                    "Abonnez-vous à Swipe & Win, jouez au jeu et réclamez les prix."}
                  {getLang === "ar" &&
                    "اشترك في خدمة تحدي المعرفة للحصول على فرصة لاختبار وتنمية معلوماتك العامة"}
                </li>
                <li className="pt-2">
                  {getLang === "en" &&
                    "Answer questions by swiping photos left."}
                  {getLang === "fr" &&
                    "Répondez aux questions en balayant les photos vers la gauche."}
                  {getLang === "ar" &&"أجب عن الاسئلة عن طريق الضغط على علامة صح أو خطأ"}
                </li>
                <li className="pt-2">
                  {getLang === "en" &&
                    "You have 45 seconds to answer as many questions as possible"}
                  {getLang === "fr" &&
                    "Vous avez 45 secondes pour répondre au maximum de questions"}
                  {getLang === "ar" &&
                   "لديك ١٠ ثواني للإجابة على كل سؤال"}
                </li>
                <li className="pt-2">
                  {getLang === "en" &&
                    "Be careful! For each wrong answer you lose 5 seconds"}
                  {getLang === "fr" &&
                    "Fais attention! Pour chaque mauvaise réponse vous perdez 5 secondes"}
                  {getLang === "ar" && "كل اختبار يتكون من ١٠ أسئلة"}
                </li>
                <li className="pt-2">
                  {getLang === "en" &&
                    "What to improve your score? Buy additional game"}
                  {getLang === "fr" &&
                    "De quoi améliorer votre score ? Acheter un jeu supplémentaire"}
                  {getLang === "ar" && "كيفية تحسين درجاتك؟ التحضير الجيد للأسئلة من خلال المعلومات اليومية"}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default HowToPlay;
