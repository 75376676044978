export const getTotalDuration = (storageType) => {
    let totalDuraction
    if(storageType === "local"){
      totalDuraction= new Date().getTime() - localStorage.getItem("localStartTime")
    }else if(storageType === "session"){
      totalDuraction= new Date().getTime() - sessionStorage.getItem("sessionStartTime")
    }
    return totalDuraction
  };

  export const  detectBrowser = () => {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
      return "Opera";
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return "Firefox";
    } else if (
      navigator.userAgent.indexOf("MSIE") != -1 ||
      !!document.documentMode == true
    ) {
      return "IE"; //crap
    } else {
      return "Unknown";
    }
  }
  