import { operatorId, elasticPublishUrl } from "../config";
import { Base64 } from "js-base64";
import { detectBrowser, getTotalDuration } from "../Utilies/util";

if (!localStorage.getItem("randomNumber")) {
  const min = 10000;
  const max = 99999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  console.log("----- random number ----->", randomNumber);
  localStorage.setItem("randomNumber", randomNumber);
}

let msisdn = "na";

var sessionTime = localStorage.getItem("sessionTime")
  ? localStorage.getItem("sessionTime")
  : "";
var sessionId = localStorage.getItem("sessionId")
  ? localStorage.getItem("sessionId")
  : "";
if (sessionTime && sessionId && Date.now() - sessionTime > 300000) {
  const a = Date.now() + "" + localStorage.getItem("randomNumber");
  localStorage.setItem("sessionId", a);
}
sessionTime = Date.now();
if (sessionId === "") {
  sessionId = sessionTime + "" + localStorage.getItem("randomNumber");
  localStorage.setItem("sessionId", sessionId);
}
localStorage.setItem("sessionTime", sessionTime);

var deviceId = localStorage.getItem("device_id")
  ? localStorage.getItem("device_id")
  : "";
if (deviceId === "") {
  deviceId = Date.now() + "" + localStorage.getItem("randomNumber");
  localStorage.setItem("device_id", deviceId);
}

export function SendEvent(data) {
  if (new Date().getTime() - sessionStorage.getItem("lastPublish") > 300000) {
    sessionStorage.setItem("sessionStartTime", new Date().getTime());
  }
  sessionStorage.setItem("lastPublish", new Date().getTime());
  var userProfile = "";
  if (localStorage.getItem("userProfile")) {
    userProfile = JSON.parse(localStorage.getItem("userProfile"));
  }

  const guiDataEvent = {
    domain: window.location.hostname,
    operator_id: operatorId,
    user_agent: navigator.userAgent,
    device_id: deviceId,
    session_id: localStorage.getItem("sessionId"),
    msisdn: localStorage.getItem("msisdn")
      ? localStorage.getItem("msisdn")
      : msisdn,
    device_type: "web",
    channel: localStorage.getItem("channel")
      ? localStorage.getItem("channel")
      : "direct",
    device_info: "na",
    browser: detectBrowser(),
    previouspage: "na",
    trace: "",
    request_time: new Date().toISOString(),
    aon_time: getTotalDuration("local"),
    session_time_duration: getTotalDuration("session"),
    session_start_time: sessionStorage.getItem("sessionStartTime"),
  };

  let tracer = localStorage.getItem("tracer")
    ? JSON.parse(localStorage.getItem("tracer"))
    : "";
  guiDataEvent["previouspage"] = tracer[tracer.length - 1];
  guiDataEvent["sub_event"] = data["page"] + "_" + data["event"];
  tracer = tracer + data["page"] + "_" + data["event"] + "|";
  localStorage.setItem("tracer", JSON.stringify(tracer));
  guiDataEvent["trace"] = tracer;

  let objData = { ...guiDataEvent, ...data };
  var jsonData_base64 = Base64.encode(JSON.stringify(objData));

  console.log("objData ::: ", objData);
  const headers = { guiTopic: "swipe4win_services" };
  fetch(elasticPublishUrl, { method: "POST", body: jsonData_base64 ,headers:headers})
    .then((resp) => {
      console.log("resp ==>>>", resp);
    })
    .catch((error) => {
      console.log("error", error);
    });
}
