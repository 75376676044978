import React, { useState ,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../Components";
import Header from "../commonComponent/Header";
import {page, event, key} from "../../events/event"
import {SendEvent} from "../../events/eventPush"

function Unsubscribe() {
  const navigate = useNavigate();

  useEffect(() => {
    let eventData = {}
    eventData[key.page] = page.unsubcribe
    eventData[key.event] = event.open
    SendEvent(eventData)
  },[])
  
  const handleButton = () => {};

  return (
    <div className="bg-darkPurple flex flex-col h-screen">
     <Header/>
      <div className="flex-grow mt-6">
        <div className="mt-2 bg-lightPurple flex text-center m-4 rounded-xl p-4 flex-col items-center text-white">
          <div className="w-full ">
            <div className="flex justify-between font-semibold w-full text-xl">
              <div>Subscription Status:</div>
              <div className="bg-lightGreen px-2 rounded-md">Active</div>
            </div>
            <div className="text-left mt-2 text-buttonYellow">Next Renewal: 4th March 2024</div>
          </div>
          <Button
            name="Unsubscribe Now"
            handleClick={handleButton}
            className="h-12 bg-buttonYellow mt-8 rounded-full font-bold  text-darkPurple text-xl w-80"
          />
        </div>
      </div>
    </div>
  );
}

export default Unsubscribe