import React, { useState ,useEffect} from "react";
import { Button } from "../../Components";
import queryString from 'query-string';
import Header from "../commonComponent/Header";
import {page, event, key} from "../../events/event"
import {SendEvent} from "../../events/eventPush"

function Subscription() {
  const {type} = queryString.parse(window.location.search);


  const [isSelected, setIsSelected] = useState(null);
  const getLang = "ar"


  useEffect(() => {
    let eventData = {}
    eventData[key.page] = page.subscription
    eventData[key.event] = event.open
    SendEvent(eventData)
  },[])

  const handleClick = (index) => {
    setIsSelected(index);
    console.log("Selected pack index::", index);
  };
  const handleSubscription = () => {};

  return (
    <div className="bg-darkPurple flex flex-col h-screen">
      <Header/>
      <div className="flex-grow mt-6">
        <SubscritionPacks
          index={1}
          handleClick={handleClick}
          isSelected={isSelected}
          getLang = {getLang}
        />
        <SubscritionPacks
          index={2}
          handleClick={handleClick}
          isSelected={isSelected}
          getLang = {getLang}
        />
        <SubscritionPacks
          index={3}
          handleClick={handleClick}
          isSelected={isSelected}
          getLang = {getLang}
        />

        <div className=" mt-10 flex flex-col items-center">
          <div className={`text-white mb-2 ${getLang==="ar"?"arabic-lang":""}`}>للوصول إلى محتوى غير محدود</div>
          <Button
            name={type === "upgrade" ? "ترقية الاشتراك" :"اشترك الآن"}
            handleClick={handleSubscription}
            className="h-12 bg-buttonYellow rounded-full font-bold  text-darkPurple text-xl w-80"
          />
        </div>
      </div>
    </div>
  );
}

function SubscritionPacks({ index, handleClick, isSelected,getLang }) {
  return (
    <div
      key={index}
      onClick={() => handleClick(index)}
      className={`border-2 h-20 m-4 rounded-lg flex justify-between ${isSelected === index && "bg-lightPurple"}`}
    >
      <div className="my-auto ml-4 text-xl text-white w-3/4">
        <div className={`${getLang==="ar"?"arabic-lang":""}`}>اشتراك يومي</div>
        <div className={`${isSelected === index ? "text-white" :"text-buttonYellow"}  font-bold ${getLang==="ar"?"arabic-lang":""}`}>0.3 دينار ليبي </div>
      </div>
      <div className="flex justify-center items-center mr-4">
        <input
          type="radio"
          id={"myRadio" + index}
          name="radioButton"
          className="hidden"
          checked={isSelected === index}
        />
        <label
          htmlFor={"myRadio" + index}
          className={`inline-block w-6 h-6 rounded-full ${
            isSelected === index ? "bg-white" : "border border-white"
          } flex justify-center items-center`}
        >
          {isSelected === index && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="green"
                stroke="green"
                stroke-width="2"
                d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
              />
            </svg>
          )}
        </label>
      </div>
    </div>
  );
}

export default Subscription;
