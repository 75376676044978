import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../Components";
import { checkSubcription } from "../API/apiRequest";
import Loader from "../commonComponent/Loader";
import { countryCode, heURl } from "../config/wapConfig";
import {page, event, key} from "../../events/event"
import {SendEvent} from "../../events/eventPush"
import { Base64 } from "js-base64";
import { injectScript } from "../../script/injectScript";


function LandingPage() {
  const upperBgImage = require("../../Assets/wap/daretoknowAssets/login_page_banner.jpg");
  const lowerBgImage = require("../../Assets/wap/zainIraqS4W/bottomDarkPurple.png");

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [msisdn, setMsisdn] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const uniqId = urlParams.get("uniqid");
  const msisdnParam = urlParams.get("msisdn");
  

  useEffect(() => {
    let eventData = {};
    eventData[key.page] = page.landing;
    eventData[key.event] = event.open;
    eventData[key.uuid] = uniqId
    eventData[key.msisdn] = msisdnParam
    eventData[key.redirectedUrl] = window.location.href;
    SendEvent(eventData);
    if(uniqId)
    {
      injectScript();
    }
  }, [uniqId]);

  const handleSubscribeButton = () => {
    if (msisdn && msisdn !== "") {
      checkSubcription(msisdn)
        .then((resp) => {
          const currentStatus = resp?.currentStatus?.toLowerCase();
          console.log("Checksub api response::", resp);
          if (currentStatus === "active" || currentStatus === "demo") {
            navigate("/home");
          } else {
            navigate("sub-status");
          }
        })
        .catch((err) => {
          console.log("checksub api error::", err);
        });
    } else {
      navigate("/login");
    }
  };

  const handleCheckSub = (msisdnParam) => {
    const msisdnToUse = msisdnParam.length > 10 ? msisdnParam.slice(-10) : msisdnParam;
    const uid = `m=${countryCode.substring(1) + msisdnToUse}`;
    checkSubcription(msisdnToUse)
      .then((resp) => {
        console.log("Checksub api response::", resp);
        if (resp) {
          setIsLoader(false);
          const currentStatus = resp?.currentStatus?.toLowerCase();
          if (currentStatus === "active" || currentStatus === "demo") {
            navigate(`/home?uid=${Base64.encode(uid)}`);
          } else {
            navigate(`/sub-status?id=1`);
          }
        }
      })
      .catch((err) => {
        setIsLoader(false);
        console.log("Checksub api error::", err);
      });
  };

  useEffect(() => {
    setIsLoader(true);
    const success = urlParams.get("success");
    const fail = urlParams.get("fail");
    const msisdnParam = urlParams.get("msisdn");
    const UID = urlParams.get("uid");
    const uid = localStorage.getItem("uid");
    const uniqId = urlParams.get("uniqid");
    localStorage.setItem("uniqid",uniqId)
   const reason = decodeURIComponent(urlParams.get("reason") || "");

    console.log('reason',reason)
    if(msisdnParam && uniqId){
      callback(msisdnParam,uniqId)
    }

    if (success && msisdnParam) {
      handleCheckSub(msisdnParam);
    } else if (fail && !reason.includes("Subscriptions Exceeded")) {
      navigate("/login");
    } else if (UID) {
      navigate(`/home?uid=${UID}`);
    } else if (uid) {
      navigate("/home");
    } else if (reason && reason.includes("Subscriptions Exceeded")) {
      console.log("entered in reason");
      navigate(`/sub-status?id=8`);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const callback = (msisdnParam,uniqId) => {
    console.log("callback function called");
    const url = "https://camp-reports.bngrenew.com:5455/digitalservice/api/updatemsisdn?uuid="+uniqId+"&msisdn="+msisdnParam ;
    const headers = {
      defaultlanguage: "ar",
      timezone: "+3",
      operatorid: "zain_iraq",
      "Content-Type": "application/json",
      accept: "application/json",
    };
    fetch(url, {
      method: "GET",
      // body: JSON.stringify({ uuid: uniqId, msisdn: msisdnParam }),
      headers: headers,
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (result) => {
          if (result === "ok") {
            // checksub()
          }
        },
        (error) => {
          console.log("error:::", error);
        }
      );
  };

  return (
    <>
      {isLoader && <Loader />}
      <div className="bg-skyBlue">
        <div className="h-[10vh] rounded-b-3xl bg-white flex justify-center items-center">
          <img className="w-28 h-10" src={require("../../Assets/wap/zainIraqS4W/app_text.png")}></img>
        </div>
        <div className="h-[90vh] g-buttonYellow">
          <img src={upperBgImage} className="w-full h-[35vh]" />

          <div className="relative">
            <img src={lowerBgImage} className="w-full  -mt-8 h-[65vh]" />
            <div className="absolute top-6 left-0 w-full h-full flex flex-col  items-center">
              <div dir="rtl" className={`text-buttonYellow mb-2 text-2xl font-bold`}>
                تحدي المعرفة
              </div>
              <Button
                name="إشترك الآن"
                handleClick={handleSubscribeButton}
                className="h-10 bg-buttonYellow mt-8 rounded-full font-semibold  text-darkPurple text-lg px-20"
              />
              <div dir="rtl" className={`pr-2 text-white text-right my-4`}>
                <p dir="rtl">
                  باشتراكك في خدمة تحدي المعرفة، انك توافق على الشروط والاحكام التالية :
                </p>
                <p dir="rtl"> سعر الخدمة 240 دينار عراقي يومياً</p>
                <p dir="rtl">
                  فترة اشتراك مجانية ليوم واحد لكل المشتركين الجدد فقط
                </p>
                <p dir="rtl"> سيتم تجديد الاشتراك يومياً وبشكل تلقائي</p>
                <p> يمكنك إلغاء الاشتراك في أي وقت من خلال ارسال D2 إلى 3368 </p>
                <p dir="rtl" className={`text-buttonYellow text-xs text-right`}>
                  للدعم التقني قم بالتواصل عبر:
                  (mobileapps@blackngreen.com)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPage;

