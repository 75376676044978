import React from "react";
import { Home } from "./Home";

export const ProtectedRoute = ( props ) => {
 const {Component} = props;
  let isLoggedIn = false;
  const uid = localStorage.getItem("uid");
  if (uid) {
    isLoggedIn = true;
  }

  if (isLoggedIn) {
    return <Component />;
  } else {
    return (
        <Home />
    );
  }
};

// return <Component />
