import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../Components";
import { sendOtp, checkSubcription, sendPincode,register } from "../API/apiRequest";
import Loader from "../commonComponent/Loader";
import { page, event, key } from "../../events/event";
import { SendEvent } from "../../events/eventPush";
import { countryCode } from "../config/wapConfig";
import { API_SWIP_URLS } from "../../Shared/Constant";
import { POST } from "../../Shared/Axios";
import { Base64 } from "js-base64";
import { Modal } from "../../Components/Modal"

function Login() {
  const upperBgImage = require("../../Assets/wap/daretoknowAssets/login_page_banner.jpg");
  const lowerBgImage = require("../../Assets/wap/zainIraqS4W/bottomDarkPurple.png");

  const navigate = useNavigate();
  const location = useLocation()
  const [msisdn, setMsisdn] = useState();
  const [isDisable, setIsDisable] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [sms,setSms] = useState(true)
  const [registerData,setRegisterData] = useState()
  const getLang = "ar";
  const uid = `m=${countryCode.substring(1)+msisdn}`;
  const uniqId = localStorage.getItem("uniqid")


  useEffect(() => {
    let eventData = {};
    eventData[key.page] = page.login;
    eventData[key.event] = event.open;
    SendEvent(eventData);
  }, []);

  const handleMsisdn = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value) && value.length <= 10) { // Allow only numeric input and max 9 digits
      setMsisdn(value);
      setIsDisable(value.length !== 10);
    }
  };

  const registerApiCall = (msisdn) => {
    msisdn = countryCode.substring(1)+msisdn
    const body = {
      operatorId: "zain_iraq",
      userId: msisdn,
    };
  
  
      POST(API_SWIP_URLS.Register, body)
        .then((res) => {
          setRegisterData(res);
        })
        .catch((err) => console.log(err));
  };

  const Callback = (msisdn,uniqId) => {
    console.log("callback function called");
    const url = "https://camp-reports.bngrenew.com:5455/digitalservice/api/updatemsisdn?uuid="+uniqId+"&msisdn=964"+msisdn ;
    const headers = {
      defaultlanguage: "ar",
      timezone: "+3",
      operatorid: "zain_iraq",
      "Content-Type": "application/json",
      accept: "application/json",
    };
    fetch(url, {
      method: "GET",
      // body: JSON.stringify({ uuid: uniqId, msisdn: msisdnParam }),
      headers: headers,
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (result) => {
          if (result === "ok") {
            // checksub()
          }
        },
        (error) => {
          console.log("error:::", error);
        }
      );
  };

  const handleLoginButton = async () => {
    Callback(msisdn,uniqId)
    try {
      console.log("msisdn entered:::", msisdn);
      localStorage.setItem("msisdn", msisdn);

      let eventData = {};
      eventData[key.page] = page.login;
      eventData[key.event] = event.loginClick;
      SendEvent(eventData);

      if (msisdn) {
        setIsLoader(true);

        eventData = {};
        eventData[key.page] = page.login;
        eventData[key.event] = event.checksubMsisdnReq;
        SendEvent(eventData);

        // Call register API and wait for it to complete
        await registerApiCall(msisdn);

        // Call checkSubcription after register API completes
        checkSubcription(msisdn)
          .then((resp) => {
            const currentStatus = resp?.currentStatus?.toLowerCase();

            eventData = {};
            eventData[key.page] = page.login;
            eventData[key.event] = event.checksubMsisdnRes;
            eventData[key.status] = currentStatus;
            SendEvent(eventData);

            console.log("Checksub api response::", resp);
            if (currentStatus === "active" || currentStatus === "demo") {
              eventData = {};
              eventData[key.page] = page.login;
              eventData[key.event] = event.sendOtpReq;
              SendEvent(eventData);
              navigate(`/home?uid=${Base64.encode(uid)}`);

              // sendOtp(msisdn)
              //   .then((resp) => {
              //     eventData = {};
              //     eventData[key.page] = page.login;
              //     eventData[key.event] = event.sendOtpRes;
              //     eventData[key.status] = resp?.status?.toLowerCase();
              //     SendEvent(eventData);

              //     if (resp?.status?.toLowerCase() === "success") {
              //       setSms(true);
              //       console.log(sms, "sms");
              //       navigate("/otp?type=otp", { state: { sms: true } });
              //       setIsLoader(false);
              //     }
              //     console.log("sendOtp reponse::", resp);
              //   })
              //   .catch((err) => {
              //     setIsLoader(false);
              //     console.log("sendOtp api error::", err);
              //   });
            } else {
              eventData = {};
              eventData[key.page] = page.login;
              eventData[key.event] = event.sendPincodeReq;
              SendEvent(eventData);

              sendPincode(msisdn)
                .then((resp) => {
                  eventData = {};
                  eventData[key.page] = page.login;
                  eventData[key.event] = event.sendPincodeRes;
                  eventData[key.status] = resp?.status?.toLowerCase();
                  SendEvent(eventData);

                  if (resp?.status?.toLowerCase() === "success") {
                    navigate("/otp?type=pincode");
                  }
                  if(resp.message === "Failed to send pincode: Max. Subscriptions Reached, 24 Hours Rule."){
                    navigate(`/sub-status?id=8`);
                  }
                  
                  setIsLoader(false);
                  console.log("sendPincode reponse::", resp);
                })
                .catch((err) => {
                  setIsLoader(false);
                  console.log("sendPincode api error::", err);
                });
            }
          })
          .catch((err) => {
            setIsLoader(false);
            console.log("Send otp api error::", err);
          });
      }
    } catch (err) {
      setIsLoader(false);
      console.log("Registration or Subscription check error::", err);
    }
  };


  return (
    <>
      {" "}
      {isLoader && <Loader />}
      <div className="bg-skyBlue">
        <div className="h-[10vh] rounded-b-3xl bg-white flex justify-center items-center">
          <img
            className="w-28 h-10"
            src={require("../../Assets/wap/zainIraqS4W/app_text.png")}
          ></img>
        </div>
        <div className="h-[90vh] g-buttonYellow">
          <img src={upperBgImage} className="w-full h-[50vh]" />

          <div className="relative">
            <img src={lowerBgImage} className="w-full  -mt-8 h-[45vh]" />
            <div className="absolute top-10 left-0 w-full h-full flex flex-col  items-center">
              <div
                className={`text-buttonYellow mb-6 text-xl font-semibold ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                تأكيد رقم الهاتف
              </div>
              <div className="flex h-10">
                <div className="text-white bg-lightPurple px-4 flex justify-center items-center rounded-l-full">
                  +964
                </div>
                <input
                  type="text"
                  name="msisdn"
                  value={msisdn}
                  onChange={handleMsisdn}
                  className="rounded-r-full pl-2"
                  placeholder="ادخل الرقم"
                />
              </div>
              <Button
                name="التالي"
                handleClick={handleLoginButton}
                disabled={isDisable}
                className="h-10 bg-white mt-8 rounded-full font-semibold  text-darkPurple text-lg px-20"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
