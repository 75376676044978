import React, { useEffect } from "react";
import {
  avtar,
  BannerImg,
  First,
  OtherMedal,
  Second,
  Third,
} from "../../Utilies/ImageExports";
import "./leaderBoarder.scss";
import { Loader, Header } from "..";
import { API_SWIP_URLS } from "../../Shared/Constant";
import useApiCall from "../../Utilies/useApiCall";
import { operatorId } from "../../config";

const LeaderBoard = () => {
  const uid = window.localStorage.getItem("user-msisdn");
  const [leaderBoardData, apiCall, loading] = useApiCall(
    API_SWIP_URLS.LeaderBoard,
    { operatorId: operatorId, userId: uid ? uid : "" }
  );
  const getLang = window.localStorage.getItem("lang");

  useEffect(() => {
    apiCall();
  }, []);

  const ConvertToArabicNumbers = (num) => {
    const newNum = num <= 9 ? `${num}0` : num;
    const arabicNumbers =
      "\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669";
    return new String(newNum).replace(/[0123456789]/g, (d) => {
      return arabicNumbers[d];
    });
  };

  return (
    <div
      className="main "
      style={{ overflowY: "scroll", backgroundColor: "white" }}
    >
      <Header text={"leaderboard"} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="">
            <div className="-ml-4 -mr-4 -mt-2">
              <img
                src={BannerImg}
                width="100%"
                alt="mtn_banner"
                className="bg-[#4b0083] rounded-b-3xl"
              />{" "}
            </div>
          </div>
          <div className="h-screen">
             {leaderBoardData?.yourscore && (
              <div className={`flex mt-4 bg-[#9a75ff] pt-2 rounded-lg mb-2 items-center`}>
                <div className="flex justify-around mx-1 h-10 mb-2 w-[70%] p-2 bg-[#4b0083] text-white rounded-lg  ">
                  <div>
                    {" "}
                    {getLang === "en" && `${leaderBoardData?.yourscore?.score}`}
                    {getLang === "fr" && `${leaderBoardData?.yourscore?.score}`}
                    {getLang === "ar" &&
                      ` ${leaderBoardData?.yourscore?.score}`}
                  </div>
                  <div className="text-white flex justify-end">
                    {getLang === "en" && leaderBoardData?.yourscore?.username}
                    {getLang === "fr" && leaderBoardData?.yourscore?.username}
                    {getLang === "ar" && leaderBoardData?.yourscore?.username}
                  </div>
                </div>
                <div className="mt-auto mb-auto mx-2 text-white">
                  <img
                    src={avtar}
                    alt="avtar"
                    width=""
                    className="rounded-full "
                  />
                </div>
                <div className={`mt-2 mx-3 mb-2  ${getLang === "ar" &&
                        leaderBoardData?.yourscore?.rank !== 3 &&
                        leaderBoardData?.yourscore?.rank !== 2 &&
                        leaderBoardData?.yourscore?.rank !== 1&&"text-center"}`} >
                        {getLang === "en"
                          ? leaderBoardData?.yourscore?.rank <= 9
                            ? `0${leaderBoardData?.yourscore?.rank}`
                            : leaderBoardData?.yourscore?.rank
                          : ""}
                        {getLang === "fr"
                          ? leaderBoardData?.yourscore?.rank <= 9
                            ? `0${leaderBoardData?.yourscore?.rank}`
                            : leaderBoardData?.yourscore?.rank
                          : ""}
                        {getLang === "ar" && leaderBoardData?.yourscore?.rank == 1 ? (
                          <img src={First} />
                        ) : (
                          ""
                        )}
                        {getLang === "ar" && leaderBoardData?.yourscore?.rank == 2 ? (
                          <img src={Second} />
                        ) : (
                          ""
                        )}
                        {getLang === "ar" && leaderBoardData?.yourscore?.rank == 3 ? (
                         <div className="relative">
                         <img src={Third} alt="third medal" className="medal" />
                         <div className="absolute inset-1 flex items-center justify-center text-brown-800 mt-[.70rem] text-[12px] font-bold">{leaderBoardData?.yourscore?.rank}</div>
                       </div>
                         
                        ) : (
                          ""
                        )}
                        {getLang === "ar" &&
                       leaderBoardData?.yourscore?.rank !== 3 &&
                       leaderBoardData?.yourscore?.rank !== 2 &&
                       leaderBoardData?.yourscore?.rank !== 1
                          ? <div className="relative">
                          <img src={OtherMedal} alt="third medal" className="medal" />
                          <div className="absolute inset-1 text-[12px] flex items-center justify-center text-blue-700 mt-[.70rem] font-bold">{leaderBoardData?.yourscore?.rank}</div>
                        </div>
                          : ""}
                      </div>
              </div>
            )} 
            <div className={` ${!leaderBoardData?.yourscore ? "mt-8" : ""}`}>
              {leaderBoardData &&
                leaderBoardData?.leaderboard?.map((item, i) => {
                  return (
                    <>
                  {item.score!==0&& <div className="flex">
                      <div
                        className={`flex justify-around mx-1 mb-2 w-[70%] p-2 bg-[#4b0083] text-white rounded-lg `}
                      >
                        <div>
                          {" "}
                          {getLang === "en" && `${item?.score}`}
                          {getLang === "fr" && `${item?.score}`}
                          {getLang === "ar" && ` ${item?.score}`}
                        </div>
                        <div className="text-white flex justify-end">
                          {getLang === "en" && item?.username}
                          {getLang === "fr" && item?.username}
                          {getLang === "ar" && item?.username}
                        </div>
                      </div>
                      <div className=" mt-auto mb-auto  mx-2 text-white">
                        <img
                          src={avtar}
                          alt="avtar"
                          width=""
                          className="rounded-full"
                        />
                      </div>
                      <div className={`mt-2 mx-3 text-center flex items-center mb-2 `} >
                        {getLang === "en"
                          ? item?.rank <= 9
                            ? `0${item.rank}`
                            : item.rank
                          : ""}
                        {getLang === "fr"
                          ? item?.rank <= 9
                            ? `0${item.rank}`
                            : item.rank
                          : ""}
                        {getLang === "ar" && item?.rank == 1 ? (
                          <img src={First} />
                        ) : (
                          ""
                        )}
                        {getLang === "ar" && item?.rank == 2 ? (
                          <img src={Second} />
                        ) : (
                          ""
                        )}
                        {getLang === "ar" && item?.rank === 3 ? (
                           <div className="relative">
                           <img src={Third} alt="third medal" className="medal" />
                           <div className="absolute inset-1 text-[12px] flex items-center justify-center text-brown-800 mt-[.70rem] font-bold">{item?.rank}</div>
                         </div>
                        ) : (
                          ""
                        )}
                        {getLang === "ar" &&
                        item?.rank !== 3 &&
                        item?.rank !== 2 &&
                        item?.rank !== 1
                          ?   <div className="relative">
                          <img src={OtherMedal} alt="third medal" className="medal" />
                          <div className="absolute inset-1 text-[12px] flex items-center justify-center text-blue-700 mt-[.70rem] font-bold">{item?.rank}</div>
                        </div>
                          : ""}
                      </div>
                    
                    </div>}
                    </>
                  );
                })}
            </div>
           
          </div>
        </>
      )}
    </div>
  );
};
export default LeaderBoard;
