import React, { useState, useEffect } from "react";
import { Button } from "../../Components";
import OtpInput from "./OtpInput";
import CountdownTimer from "../commonComponent/CountdownTimer";
import Header from "../commonComponent/Header";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  otpVerify,
  pincodeVerify,
  sendOtp,
  sendPincode,
  checkSubcription,
} from "../API/apiRequest";
import Loader from "../commonComponent/Loader";
import { page, event, key } from "../../events/event";
import { SendEvent } from "../../events/eventPush";
import { Base64 } from "js-base64";
import { countryCode } from "../config/wapConfig";

function Otp() {
  const location = useLocation();
  const sms = location?.state?.sms;
  console.log(sms,"sms")
  const TIME_LEFT_FOR_OTP = 120;
  const OTP_LENGTH = sms ? 4 : 5 
  const msisdn = localStorage.getItem("msisdn");
  const { type } = queryString.parse(window.location.search);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isWrongOTP, setIsWrongOTP] = useState(false);
  const [otpTime, setOtpTime] = useState(TIME_LEFT_FOR_OTP);
  const [isLoader, setIsLoader] = useState(false);
  const getLang = "ar";
  const uid = `m=${countryCode.substring(1)+msisdn}`;

  useEffect(() => {
    let eventData = {};
    eventData[key.page] = page.otp;
    eventData[key.event] = event.open;
    SendEvent(eventData);
  }, []);

  const handleOtpVerify = () => {
    let eventData = {};
    eventData[key.page] = page.otp;
    eventData[key.event] = event.verifyOtpClick;
    SendEvent(eventData);

    setIsLoader(true);
    if (type === "pincode") {
      let eventData = {};
      eventData[key.page] = page.otp;
      eventData[key.event] = event.verifyPincodeReq;
      eventData[key.otp] = otp;
      SendEvent(eventData);

      pincodeVerify(msisdn, otp)
        .then((resp) => {
          let eventData = {};
          eventData[key.page] = page.otp;
          eventData[key.event] = event.veriifyPincodeRes;
          eventData[key.otp] = otp;
          eventData[key.status] = resp?.status?.toLowerCase();
          SendEvent(eventData);

          if (resp?.status?.toLowerCase() === "success") {
            handlePinCheckSub();
          } else {
            setIsWrongOTP(true);
            setOtp("");
            setIsLoader(false);
          }
          console.log("pincodeVerify api resp::", resp);
        })
        .catch((err) => {
          setIsLoader(false);
          console.log("pincodeVerify api error ::", err);
        });
    } else {
      let eventData = {};
      eventData[key.page] = page.otp;
      eventData[key.event] = event.verifyOtpReq;
      eventData[key.otp] = otp;
      SendEvent(eventData);

      otpVerify(msisdn, otp)
        .then((resp) => {
          let eventData = {};
          eventData[key.page] = page.otp;
          eventData[key.event] = event.verifyOtpRes;
          eventData[key.otp] = otp;
          eventData[key.status] = resp?.status?.toLowerCase();
          SendEvent(eventData);

          if (resp?.status?.toLowerCase() === "success") {
            handleOtpCheckSub();
          } else {
            setIsWrongOTP(true);
            setIsLoader(false);
            setOtp("");
          }

          console.log("otpVerify api resp::", resp);
        })
        .catch((err) => {
          setIsLoader(false);
          console.log("otpVerify api error ::", err);
        });
    }
  };

  const handlePinCheckSub = () => {
    let eventData = {};
    eventData[key.page] = page.otp;
    eventData[key.event] = event.checksubMsisdnReq;
    SendEvent(eventData);

    checkSubcription(msisdn)
      .then((resp) => {
        console.log("Checksub api response::", resp);
        if (resp) {
          setIsLoader(false);
          const currentStatus = resp?.currentStatus?.toLowerCase();

          let eventData = {};
          eventData[key.page] = page.otp;
          eventData[key.event] = event.checksubMsisdnRes;
          eventData[key.status] = currentStatus;
          SendEvent(eventData);

          if (currentStatus === "active" || currentStatus === "demo") {
            navigate(`/sub-status?id=7`);
          } else if (currentStatus === "grace"){
            navigate(`/sub-status?id=4`);
          }
          else{
            navigate(`/sub-status?id=1`);
          }
        }
      })
      .catch((err) => {
        setIsLoader(false);
        console.log("Checksub api error::", err);
      });
  };

  const handleOtpCheckSub = () => {
    let eventData = {};
    eventData[key.page] = page.otp;
    eventData[key.event] = event.checksubMsisdnReq;
    SendEvent(eventData);

    checkSubcription(msisdn)
      .then((resp) => {
        console.log("Checksub api response::", resp);
        if (resp) {
          setIsLoader(false);
          const currentStatus = resp?.currentStatus?.toLowerCase();

          let eventData = {};
          eventData[key.page] = page.otp;
          eventData[key.event] = event.checksubMsisdnRes;
          eventData[key.status] = currentStatus;
          SendEvent(eventData);

          if (currentStatus === "active" || currentStatus === "demo") {
            navigate(`/home?uid=${Base64.encode(uid)}`);
          } else {
            navigate(`/sub-status?id=4`);
          }
        }
      })
      .catch((err) => {
        setIsLoader(false);
        console.log("Checksub api error::", err);
      });
  };

  const handleResendOTP = () => {
    setIsLoader(false);
    setOtpTime(TIME_LEFT_FOR_OTP);
    if (type === "pincode") {

      let eventData = {};
      eventData[key.page] = page.otp;
      eventData[key.event] = event.sendPincodeReq;
      SendEvent(eventData);

      sendPincode(msisdn)
        .then((resp) => {
          setIsLoader(false);

          let eventData = {};
          eventData[key.page] = page.otp;
          eventData[key.event] = event.sendPincodeRes;
          eventData[key.status] = resp?.status?.toLowerCase();
          SendEvent(eventData);

          if (resp?.status?.toLowerCase() === "success") {
            setOtp("");
          }
          console.log("sendPincode reponse::", resp);
        })
        .catch((err) => {
          setIsLoader(false);
          console.log("sendPincode api error::", err);
        });
    } else {
      let eventData = {};
      eventData[key.page] = page.otp;
      eventData[key.event] = event.sendOtpReq;
      SendEvent(eventData);

      sendOtp(msisdn)
        .then((resp) => {
          setIsLoader(false);

          let eventData = {};
          eventData[key.page] = page.otp;
          eventData[key.event] = event.sendOtpRes;
          eventData[key.status] = resp?.status?.toLowerCase();
          SendEvent(eventData);

          if (resp?.status?.toLowerCase() === "success") {
            setOtp("");
            navigate("/home");
          }
          console.log("sendOtp reponse::", resp);
        })
        .catch((err) => {
          setIsLoader(false);
          console.log("sendOtp api error::", err);
        });
    }
  };

  return (
    <>
      {isLoader && <Loader />}
      <div className="bg-darkPurple flex flex-col h-screen">
        <Header />
        <div className="flex-grow mt-6">
          <div className="mt-2 flex text-center flex-col items-center text-white">
            <div className="h-20 w-20 rounded-full mb-2">
              <img
                src={
                  isWrongOTP
                    ? require("../..//Assets/wap/daretoknowAssets/wrong_otp.png")
                    : require("../../Assets/wap/daretoknowAssets/otp.png")
                }
              />
            </div>
            <div
              className={`font-semibold text-3xl ${
                getLang === "ar" ? "arabic-lang" : ""
              }`}
            >
              {isWrongOTP === true ? "عذراً" : "الرقم السري المتغير OTP"}
            </div>
            <div className="my-4">
              <div
                className={`max-w-80 w-80 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {isWrongOTP
                  ? "الرقم السري المتغير OTP الذي أدخلته غير صحيح"
                  : sms ? "الرجاء إدخال الرمز المكوّن من 4 أرقام الذي تلقيته على هاتفك المحمول" :" الرجاء إدخال الرمز المكوّن من 5 أرقام الذي تلقيته على هاتفك المحمول"}
              </div>
              {isWrongOTP && (
                <div
                  className={`text-buttonYellow text-lg mb-4 ${
                    getLang === "ar" ? "arabic-lang" : ""
                  }`}
                >
                  حاول مرة أخرى
                </div>
              )}
            </div>

            <OtpInput length={OTP_LENGTH} setOtp={setOtp} otp={otp} />
            {sms ? <div
              className={`w-64 mt-4 flex ${
                getLang === "ar" ? "arabic-lang" : ""
              }`}
            >
                احصل على الرقم السري المتغير المكوّن من 4 أرقام على هاتفك {" "}
              <span className="text-buttonYellow ml-2">
                <CountdownTimer initialTime={otpTime} />
              </span>
            </div> : <div
              className={`w-64 mt-4 flex ${
                getLang === "ar" ? "arabic-lang" : ""
              }`}
            >
                احصل على الرقم السري المتغير المكوّن من 5 أرقام على هاتفك {" "}
              <span className="text-buttonYellow ml-2">
                <CountdownTimer initialTime={otpTime} />
              </span>
            </div>}
            <div className={`${getLang === "ar" ? "arabic-lang" : ""}`}>
              لم تتلقي رسالة نصية؟
              <span
                onClick={handleResendOTP}
                className={`text-buttonYellow cursor-pointer ml-2 font-semibold ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                إعادة إرسال الرمز
              </span>
            </div>
            <Button
              name="تأكيد الشراء"
              handleClick={handleOtpVerify}
              className="h-12 bg-buttonYellow mt-8 rounded-full font-bold  text-darkPurple text-xl w-80"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Otp;
