import React, { useState ,useEffect} from "react";
import { Button } from "../../Components";
import {subStatusData} from "../data/data";
import Header from "../commonComponent/Header";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { countryCode } from "../config/wapConfig";
import { Base64 } from 'js-base64';
import {page, event, key} from "../../events/event"
import {SendEvent} from "../../events/eventPush"

function SubStatus() {
  
  const navigate = useNavigate();
  const {id} = queryString.parse(window.location.search)
  // id = parseInt(id)
  console.log("id ::",id)
  const data = subStatusData.find((item) => item.id == id);
  const msisdn = localStorage.getItem("msisdn")
  const uid = `m=${countryCode.substring(1)+msisdn}`;


  useEffect(() => {
    let eventData = {}
    eventData[key.page] = page.subStatus
    eventData[key.event] = event.open
    SendEvent(eventData)
  },[])


  const handleButton = () => {
    if (id == 7) {
      navigate(`/home?uid=${Base64.encode(uid)}`)
    }
  };

  return (
    <div className="bg-darkPurple flex flex-col h-screen">
    <Header/>
      <div className="flex-grow mt-6">
        <div className="mt-2 flex text-center flex-col items-center text-white">
          <img className="h-20 w-20 rounded-full mb-2" src={data?.icon}/>
          <div className={`text-2xl font-semibold px-2 ${id == 8 ? 'mt-[5rem]' : 'my-4'}`}>{data?.heading}</div>
          <div className="w-80 my-2">{data?.text1}</div>
         { data?.text2 && <div className="w-80 text-buttonYellow">{data?.text2}</div>}
          {data?.btn && <Button
            name={data?.btn}
            handleClick={handleButton}
            className="h-12 bg-buttonYellow mt-8 rounded-full font-bold  text-darkPurple text-xl w-80"
          />}
        </div>
      </div>
    </div>
  );
}

export default SubStatus;
