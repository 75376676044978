// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyA1vQI0gJ1Poo70nLCZ5obl39Ncrrq-QkI",
  authDomain: "swipe4win.firebaseapp.com",
  databaseURL: "https://swipe4win-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "swipe4win",
  storageBucket: "swipe4win.appspot.com",
  messagingSenderId: "887057575813",
  appId: "1:887057575813:web:c1b70d98d2f7c6acfadeec",
  measurementId: "G-9Q3HV7H337"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
